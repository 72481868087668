import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-6 md:p-12 bg-gray-50 min-h-screen">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-semibold text-[#15508B] mb-8">Privacy Policy for EasySkill Career Academy</h1>

        <section className="space-y-6">
          <div>
            <h3 className="text-xl font-semibold text-gray-800">1. Information Collection</h3>
            <p className="text-gray-700 mt-2">We collect the following information from students and users:</p>
            <ul className="list-disc list-inside text-gray-700 mt-2 ml-4 space-y-1">
              <li>Full Name</li>
              <li>Email ID</li>
              <li>WhatsApp Number</li>
            </ul>
            <p className="text-gray-700 mt-2">This information is primarily collected through inquiries.</p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">2. Purpose of Information Collection</h3>
            <p className="text-gray-700 mt-2">
              The information collected is used for communication purposes and to provide the best knowledge and services to our students.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">3. Information Sharing</h3>
            <p className="text-gray-700 mt-2">
              We respect your privacy and do not share your personal information with third parties.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">4. Data Retention</h3>
            <p className="text-gray-700 mt-2">
              Your information is retained for a period of 6 months.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">5. User Rights</h3>
            <p className="text-gray-700 mt-2">
              We recognize the rights of our users regarding their data. You may request access, correction, or deletion of your information.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">6. Security Measures</h3>
            <p className="text-gray-700 mt-2">
              We prioritize data security; however, please contact us if you have questions about specific measures we take.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">Contact Us</h3>
            <p className="text-gray-700 mt-2">If you have any questions about our Privacy Policy, feel free to reach out to us at:</p>
            <ul className="text-gray-700 mt-2 space-y-2">
              <li><strong>Phone:</strong> +91 90815 45252</li>
              <li><strong>Email:</strong> <a href="mailto:info@easyskill.in" className="text-indigo-600 hover:underline">info@easyskill.in</a></li>
              <li className='cursor-pointer' onClick={() => window.location.href = 'https://maps.app.goo.gl/a4E5L1y7hVn8VYPw9'}><strong>Branch 1 (Adajan):</strong> UG-1, 1st Floor, V3 Corner, Honey Park Rd, Adajan, Surat - 395009</li>
              <li className='cursor-pointer' onClick={() => window.location.href = 'https://maps.app.goo.gl/HnFixZ7yyiWNUR86A'}><strong>Branch 2 (Vesu):</strong> M-40, 2nd Floor, Someshwar Square, Near VNSGU Rd, Vesu, Surat - 395007</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
