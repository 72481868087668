import React from 'react';

const TermsServices = () => {
  return (
    <div className="p-6 md:p-12 bg-gray-50 min-h-screen">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-3xl font-semibold text-center text-[#15508B] mb-8">
          Terms & Conditions for EasySkill Career Academy
        </h2>

        <section className="space-y-6">
          <div>
            <h3 className="text-xl font-semibold text-gray-800">1. Eligibility</h3>
            <p className="text-gray-700 mt-2">
              Our services are available to individuals aged 10 and above, particularly those located near Surat, Gujarat.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">2. Contact Information</h3>
            <p className="text-gray-700 mt-2">
              For any inquiries regarding these Terms & Conditions, you can reach us at:
            </p>
            <ul className="text-gray-700 mt-2 space-y-2">
              <li><strong>Phone:</strong> +91 90815 45252</li>
              <li><strong>Email:</strong> <a href="mailto:info@easyskill.in" className="text-[#15508B] hover:underline">info@easyskill.in</a></li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-semibold text-gray-800">Branch Addresses:</h3>
            <ul className="text-gray-700 mt-2 space-y-2">
              <li onClick={() => window.location.href = 'https://maps.app.goo.gl/a4E5L1y7hVn8VYPw9'}><strong>Branch 1 (Adajan):</strong> UG-1, 1st Floor, V3 Corner, Honey Park Rd, Adajan, Surat - 395009</li>
              <li onClick={() => window.location.href = 'https://maps.app.goo.gl/HnFixZ7yyiWNUR86A'}><strong>Branch 2 (Vesu):</strong> M-40, 2nd Floor, Someshwar Square, Near VNSGU Rd, Vesu, Surat - 395007</li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TermsServices;
